.register_container_div {
    width: 100%;
    margin-top: 110px;
}

.register_heading_div {
    width: 100%;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    position: fixed;
    background-color: #fff;
    padding-top: 10px;
}

.register_form_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.input_group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 82%;
    margin-bottom: 10px;
    margin-top: 10px;
    max-width: 427px;
}

.input_label_register {
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #000000;
}

.input_field_register {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #000000;
}

.input_field_register2 {
    width: 105%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #000000;
}

.register_button_container {
    width: 87%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 427px;
}

.register_button {
    width: 200px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.register_button:hover {
    background-color: #333;
}

.google_register_container {
    width: 87%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 427px;
}

.google_register_button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #db4437;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.google_register_button:hover {
    background-color: #c33d2f;
}

.google_icon {
    width: 20px;
    height: 20px;
    background-image: url('https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg');
    background-size: cover;
}

.login_link {
    width: 80%;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
    color: #000000;
}

.login_link a {
    text-decoration: none;
    color: #007bff;
    font-size: 14px;
    transition: color 0.3s ease;
}

.login_link a:hover {
    color: #0056b3;
}

.registration_success {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-top: 50px;
    color: #007f00;
}
