.rates_container {
    display: flex;
    flex-direction: column;
    height: 83%;
    width: 100%;
    margin-top: 100px;
}

.course_info_container {
    display: flex;
    flex-direction: column;
    height: 83%;
    width: 100%;
    margin-top: 120px;
}

.rates_heading {
    display: flex;
    text-align: start;
    padding: 0px 20px;
    font-size: 19px;
    font-weight: 600;
    margin-top: 30px;
}

.course_info_heading {
    display: flex;
    justify-content: center;
    padding: 0px 20px;
    font-size: 19px;
    font-weight: 600;
    margin-top: 30px;
}

.rates_footer_div {
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #fff;
}

.rates_footer_button {
    width: 90%;
    font-size: 20px;
    padding: 8px 0px;
    border-radius: 5px;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: fixed;
    bottom: 20px;
}

.rates_footer_div2 {
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #fff;
}

.rates_footer_button2 {
    width: 90%;
    font-size: 20px;
    padding: 8px 0px;
    border-radius: 5px;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    box-shadow: 20px 40px 0px rgba(255, 255, 255, 1);
}

.rates_container_inner {
    overflow-y: scroll;
    padding: 0px 20px;
}

.rates_box {
    padding: 10px 0px;
}

.rates_box_inner {
    padding: 2px 0px;
    color: rgba(0, 0, 0, 0.8);
}

.rates_box_inner_header {
    padding: 2px 0px;
    font-weight: 500;
}
