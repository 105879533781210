* {
  font-family: "Noto Sans", serif;
}

body {
  margin: 0;
  height: 100vh;
}

.layout_wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
