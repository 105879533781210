.language_container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu_item_container {
    margin-top: 50px;
    flex-grow: 1;
    overflow-y: auto;
}

.img_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.bushwood_img {
    height: 25vh;
    padding: 5vh 0px 0px 0px;
}

.description_container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 23px;
    color: #000000;
    padding-inline: 20px;
    padding-bottom: 20px;
}

.choose_language_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.language_box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 35px;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 8px;
    width: 300px;
    height: 50px;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 0px;
}

.language_box:hover {
    background-color: #e0e0e0;
}

.language_footer_div {
    width: 100%;
    background-color: #3a3a3a;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 10px 0px;
    margin-top: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    position: fixed;
}

.language_footer_link {
    color: #6390b9;
    text-decoration: underline;
    font-size: 18px;
    cursor: pointer;
}

.header_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 99;
    position: fixed;
    background-color: #fff; 
}

.header_container_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.bushwood_img_header {
    height: 60px;
    padding-left: 10px;
    padding-top: 10px;
}

.humburger_container {
    font-size: 35px;
    color: rgb(0, 112, 75);
    padding: 10px;
}

.back_button_container {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 20px;
    height: 10px;
}

.back_button_text {
    font-weight: 700;
}

.home_container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 80px;
}

.img_container_home {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 5vh 0px;
}

.home_page_menu_box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 25px;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 8px;
    width: 300px;
    height: 25px;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 0px;
}

.menu_item_list {
    list-style: none;
    padding: 0px;
    padding-top: 40px;
}

.menu_item {
    margin-bottom: 30px;
    text-align: center;
    font-size: 20px;
    color: #505050;
    font-weight: 400;
}

.back_to_dashboard {
    font-weight: 600;
}

.logout_link {
    font-weight: 600;
    background-color: #b30000;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    padding: 4px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    margin-left: 30%;
    width: 40%;
    margin-right: 30%;
}

.layout_menu_wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100vh;
    background-color: #ffffff;
}

.upcoming_events_card_left_content_month {
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
    height: 35px;
}

.login_box, .login_container {
    align-items: center;
    display: flex;
}

.login_box {
    border: 1px solid #000;
    border-radius: 12px;
    box-shadow: 0 4px 4px #00000040;
    color: #5d5d5d;
    cursor: pointer;
    flex-direction: column;
    font-size: 14px;
    font-weight: 600;
    height: 20px;
    justify-content: center;
    margin: 0 0 10px;
    padding: 4px 0;
    text-align: center;
    width: 65px;
}

.upcoming_events_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 100px;
    width: 100%;
}

.upcoming_events_header {
    background-color: #fff;
    font-size: 18px;
    font-weight: 700;
    margin-top: 10px;
    padding: 20px;
    position: fixed;
    width: 100%;
}

.upcoming_events_header2 {
    background-color: #fff;
    font-size: 18px;
    font-weight: 700;
    margin-top: 10px;
    padding: 20px 0px;
    position: fixed;
    width: 100%;
    text-align: center;
}

.upcoming_events_content {
    height: 100%;
    margin-top: 5px;
    overflow-y: scroll;
}

.upcoming_events_list {
    list-style: none;
    margin-top: 70px;
    padding: 0;
    padding-inline: 20px;
}

.manu_Item_li {
    color: #505050;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
}

.manu_Item_li_bold {
    color: #505050;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
    font-weight: 600;
}

.upcoming_events_card {
    align-items: center;
    display: flex
;
    flex-direction: column;
}

.upcoming_events_card_body {
    display: flex
;
    flex-direction: row;
    justify-content: flex-start;
    padding-inline: 20px;
    width: 100%;
}

.upcoming_events_card_left_content {
    border: 1px solid #000;
    height: 70px;
    margin: 5px;
    width: 50px;
}

.upcoming_events_card_left_content_month {
    align-content: center;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    height: 35px;
}

p {
    margin: 0;
}

.upcoming_events_card_left_content_date {
    align-content: center;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    height: 35px;
}

.upcoming_events_card_right_content {
    align-items: flex-start;
    display: flex
;
    flex-direction: column;
    font-size: 16px;
    justify-content: center;
    padding-left: 20px;
}

.upcoming_events_card_footer {
    align-items: center;
    border: 1px solid #000;
    border-radius: 8px;
    box-shadow: 0 4px 4px #00000040;
    color: #000;
    cursor: pointer;
    display: flex
;
    font-size: 20px;
    height: 25px;
    justify-content: center;
    margin: 0;
    margin-block: 20px;
    padding: 10px 0;
    text-align: center;
    width: 340px;
}

.rates_container_inner2 {
    padding: 0px 20px 20px 20px;
}

/* event page  */
.event_page_container{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    height: 100%;
    /* width: 100%; */
    padding-inline: 20px;
    margin-inline: 20px;
    overflow-y: scroll;
    margin-top: 100px;
}

.event_page_container4{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    height: 100%;
    /* width: 100%; */
    padding-inline: 5px;
    margin-inline: 5px;
    overflow-y: scroll;
    margin-top: 100px;
}

.event_details_content {
    display: inline-block; /* Prevent line breaks between blocks */
    white-space: nowrap; /* Prevent wrapping */
    width: 100%; /* Make sure it takes up the full width */
    box-sizing: border-box;
  }
  
  .event_details_content img,
  .event_details_content iframe {
    max-width: 100%;
    height: auto;
    object-fit: contain; /* Ensure images and media scale correctly */
  }

.event_page_title{
    text-align: center;
    padding: 0px 10px;
    margin-top: 60px;
    font-size: 21px;
    font-weight: 700;
    color: #3a3a3a;
}

.event_page_description{
    
    text-align: center;
    margin-block: 20px;
    font-size: 18px;
    font-weight: 550;
    color: #3a3a3a;
    padding-inline: 20px;
}

.event_page_buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.event_page_button{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 20px;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 8px;
    width: 300px;
    height: 27px;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 0px;
}
.event_page_button_disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 20px;
    color: #000000;
    border: 1px solid #000000;
    background-color: #e0e0e0;
    border-radius: 8px;
    width: 300px;
    height: 27px;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 0px;
}
.event_details_title{
    text-align: center;
    padding: 0px 10px;
    margin-top: 20px;
    font-size: 21px;
    font-weight: 700;
    color: #3a3a3a;
}

.event_details_image{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}
.event_details_content{
    font-size: 16px;
    font-weight: 500;
    color: #3a3a3a;
    margin-block: 20px;
}

.terms_of_service_content{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    height: 100%;
    /* width: 100%; */
    padding-inline: 20px;
    overflow-y: scroll;
}

.login_container{
    display: flex;
    align-items: center;
}
.login_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 14px;
    color: #5d5d5d;
    border: 1px solid #000000;
    border-radius: 12px;
    width: 65px;
    height: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 4px 0px;
    font-weight: 600;
}

.course_info_container {
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    height: 100%;
    /* width: 100%; */
    padding-inline: 20px;
    overflow-y: scroll;
    margin-top: 130px;
}

.course_info_heading {
    text-align: center;
    padding: 0px 10px;
    margin-top: 30px;
    font-size: 21px;
    font-weight: 700;
    color: #3a3a3a;
}
