.promotion_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-top: 120px;
}

.promotion_heading {
    display: flex;
    text-align: start;
    align-items: start;
    padding: 10px 10px;
    font-size: 19px;
    font-weight: 600;
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 100;
}

.promotion_footer_div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.promotion_footer_button {
    width: 80%;
    font-size: 20px;
    padding: 8px 0px;
    border-radius: 5px;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: fixed;
    bottom: 20px;
}

.promotion_container_inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    padding: 10px;
    margin-bottom: 80px;
    background-color: #f8fff4;
    margin-top: 50px;
}

.promotion_card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #d4ecd8;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.promotion_card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.promotion_header {
    padding: 5px;
    font-size: 16px;
    background-color: #f0f8f5;
    border-bottom: 1px solid #d4ecd8;
}

.promotion_title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #006400;
}

.promotion_time {
    font-size: 12px;
    color: #555;
}

.promotion_image {
    object-fit: contain;
}

.promotion_footer {
    margin-top: 3px;
    padding: 15px;
    background-color: #2c7f2c;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: relative;
    overflow: hidden;
}

.promotion_footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
    animation: light-pass 2s infinite;
}

@keyframes light-pass {
    0% {
        left: -100%;
    }
    50% {
        left: 50%;
    }
    100% {
        left: 100%;
    }
}

.promotion_footer:hover {
    background-color: #006400;
    color: #fff;
    transform: scale(1.02);
}

.promotion_footer_text {
    font-size: 16px;
    font-weight: bold;
}
