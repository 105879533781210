.login_container_div {
    width: 100%;
    margin-top: 140px;
}

.login_heading_div {
    width: 100%;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
}

.login_form_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.input_group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 82%;
    margin-bottom: 10px;
    margin-top: 10px;
    max-width: 427px;
}

.input_label_login {
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #000000;
}

.input_field_login {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #000000;
}

.forgot_password_link {
    width: 80%;
    text-align: right;
    margin-bottom: 10px;
    color: #007bff;
    max-width: 427px;
}

.forgot_password_link a {
    text-decoration: none;
    color: #007bff;
    font-size: 14px;
    transition: color 0.3s ease;
}

.forgot_password_link a:hover {
    color: #0056b3;
}

.login_button_container {
    width: 83%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 427px;
}

.login_button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.login_button:hover {
    background-color: #333;
}

.google_login_container {
    width: 87%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 427px;
}

.google_login_button {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #000000;
    background-color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.google_icon {
    width: 20px;
    height: 20px;
    background-size: cover;
}

.login_session_expired {
    color: rgb(255, 79, 79);
    font-size: 15px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: -20px;
    text-align: center;
}
