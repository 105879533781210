.activate_account_container {
    background: #fff;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
    margin-top: 130px;
  }
  
  .activate_account_heading {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .activate_account_message {
    font-size: 1rem;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .success {
    color: #28a745;
  }
  
  .error {
    color: #dc3545;
  }
  
  .primary_button {
    width: 200px;
    background-color: #007bff;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
  }
  
  .primary_button:hover {
    background-color: #0056b3;
  }
