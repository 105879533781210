.event_player_buttons {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    align-items: center;
}

.players_details_button {
    width: 80%;
    position: relative;
    padding: 10px 20px;
    margin-bottom: 10px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.players_details_button:hover {
    background-color: #dadada;
}

.completed {
    background-color: #e8ffe8;
    border: 1px solid #28a745;
}

.green_tick {
    color: #28a745;
    font-size: 18px;
    margin-left: 10px;
    vertical-align: middle;
}

.book_event_button {
    width: 80%;
    font-size: 18px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #2a9400;
    color: #fff;
    margin-bottom: 20px;
}

.book_event_button:disabled {
    background-color: #757575;
}

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.3s ease-in-out;
}

.modal_container {
    background: #fff;
    border-radius: 12px;
    padding: 0px 20px 25px 20px;
    width: 90%;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
}

.modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    font-family: 'Arial', sans-serif;
}

.modal_header h2 {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.modal_close {
    background: none;
    border: none;
    font-size: 38px;
    font-weight: bold;
    cursor: pointer;
    color: #666;
    width: 10%;
}

.modal_close:hover {
    color: #000;
}

input,
        select,
        textarea,
        button {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            font-size: 14px;
            box-sizing: border-box;
        }

.modal_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal_form input {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
}

.input_group2 {
    width: 100%;
}

.modal_form input:focus {
    border-color: #2a9400;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 110, 15, 0.3);
}

.modal_submit {
    width: 100%;
    padding: 12px;
    background-color: #2a9400;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    font-weight: bold;
}

.modal_submit:hover {
    background-color: #35bb00;
    transition: background-color 0.3s ease-in-out;
}

.error_message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

@keyframes fadeIn {
    from {
        transform: scale(0.9);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.player_details_container {
    display: flex;
    flex-direction: column;
}

.player_detail {
    display: flex;
    flex-direction: column;
}

.player_detail label {
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.player_detail span {
    font-size: 18px;
    margin-bottom: 10px;
    color: #555;
}

.event_booking_done_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 150px;
}

.event_booking_done_heading {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-weight: 700;
}

.event_booking_done_content {
    width: 70%;
}
