.booking_container {
    width: 100%;
    background-color: #f8f9fa;
    color: #333;
    border-radius: 12px;
    margin-top: 30px;
    padding-top: 90px;
}

.booking_heading {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 15px;
    color: #343a40;
    padding-left: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: fixed;
    background-color: #fff;
    padding-top: 10px;
}

.tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
    position: fixed;
    margin-top: 40px;
    background-color: white;
}

.tab {
    padding-block: 12px;
    width: 100%;
    margin: 0 10px;
    cursor: pointer;
    border: 1px solid #007f00;
    background-color: #f1f1f1;
    border-radius: 8px;
    font-weight: bold;
    transition: all 0.3s ease;
    color: #007f00;
    font-size: 16px;
}

.active {
    background-color: #007f00;
    color: #fff;
}

.filters {
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    padding: 0px 10px;
}

.sort_label {
    font-size: 16px;
    color: #343a40;
    font-weight: 500;
    width: 100px;
}

.sort_select {
    padding: 5px;
    font-size: 16px;
    border: 2px solid #007f00;
    border-radius: 5px;
    background-color: #fff;
    color: #343a40;
    cursor: pointer;
    transition: all 0.3s ease;
}

.sort_order {
    padding: 8px;
    font-size: 16px;
    border: 2px solid #007f00;
    border-radius: 5px;
    background-color: #fff;
    color: #343a40;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    font-size: 18px;
}

.sort_select:hover, .sort_order:hover {
    border-color: #005500;
}

.bookings_list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 100px 0px 20px 0;
}

.booking_card {
    border: none;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-top: 10px;
}

.booking_title {
    font-size: 20px;
    font-weight: 700;
    color: #007f00;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.booking_details span {
    display: block;
    margin: 8px 0;
    font-size: 15px;
    color: #555;
}

.booking_details span:first-child {
    font-weight: bold;
}

.no_bookings {
    text-align: center;
    padding-top: 20px;
    font-size: large;
    font-weight: 600;
    color: #a82700;
}

.booking_card {
    margin: 10px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    font-family: 'Arial', sans-serif;
    color: #333333;
}

.booking_card_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
}

.booking_time,
.booking_date {
    font-size: 1.2em;
    font-weight: bold;
    color: #1fb622;
}

.booking_card_body {
    padding-top: 0px;
}

.booking_id {
    font-size: 1em;
    font-weight: bold;
    padding-bottom: 8px;
}

.booking_players,
.booking_price,
.booking_extras {
    margin-bottom: 8px;
    font-size: 0.9em;
    font-weight: 500;
}

.booking_status {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
}
