.promotion_details_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-top: 130px;
}

.promotion_details_heading {
    display: flex;
    text-align: start;
    align-items: start;
    padding: 0px 10px;
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 10px;
}

.promotion_details_container_inner {
    overflow-y: scroll;
    /* padding: 0px 10px; */
}

.promotion_image_div {
    width: 100%;
}

.promotion_image {
    width: 100%;
}

.promotion_details_heading2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.promotion_details_button {
    width: 80%;
    font-size: 20px;
    padding: 8px 0px;
    border-radius: 5px;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.promotion_details_description img {
    max-width: 100%;
    width: 100%;
}

