.typo_search_container {
    width: 100%;
    position: relative;
    font-family: Arial, sans-serif;
  }
  
  .typo_search_input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    outline: none;
    font-size: 16px;
  }
  
  .typo_search_results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .typo_search_result {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .typo_search_result:hover {
    background-color: #f0f0f0;
  }
  
  .typo_search_no_results {
    padding: 10px;
    color: #777;
    text-align: center;
    pointer-events: none; /* Disable clicking on empty results */
  }

  input,
        select,
        textarea,
        button {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            font-size: 14px;
            box-sizing: border-box;
        }
  