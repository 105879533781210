.time_sheet_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
    margin-top: 120px;
}
  
.time_sheet_heading {
    padding-top: 20px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    padding-left: 10px;
}
  
.time_sheet_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  
.time_sheet_slab {
    background: white;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
  
.slab_title {
    font-size: 15px;
    font-weight: bold;
    color: #2e7d32;
    margin-bottom: 10px;
}
  
.tee_time_item {
    background: #e8f5e9;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}
  
.tee_main_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.tee_time {
    font-size: 14px;
    font-weight: bold;
    color: #333;
}
  
.tee_seats {
    font-size: 12px;
    color: #616161;
}
  
.tee_button {
    padding: 5px 8px;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}
  
.join_button {
    background-color: #2e7d32;
    color: white;
}
  
.join_button:hover {
    background-color: #1b5e20;
}
  
.view_button {
    background-color: #757575;
    color: white;
}
  
.view_button:hover {
    background-color: #424242;
}
  
.expand_button {
    background-color: #f5f5f5;
    color: #2e7d32;
    border: 1px solid #2e7d32;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
}
  
.expand_button:hover {
    background-color: #e8f5e9;
}
  
.tee_members {
    margin-top: 10px;
    padding-left: 20px;
}
  
.members_heading {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}
  
.tee_members ul {
    padding-left: 15px;
}
  
.tee_members li {
    font-size: 12px;
    color: #616161;
    text-align: start;
}
