.verify_otp_container {
    width: 100%;
    margin-top: 140px;
}

.verify_otp_heading {
    width: 100%;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
}

.verify_otp_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.verify_otp_input_group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 87%;
    margin-bottom: 10px;
    margin-top: 10px;
    max-width: 427px;
}

.input_label_verify_otp {
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #000000;
}

.input_field_verify_otp {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #000000;
}

.verify_otp_button_container {
    width: 87%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 427px;
}

.verify_otp_button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.verify_otp_button:hover {
    background-color: #333;
}

.resend_otp_link {
    width: 80%;
    text-align: center;
    margin-top: 10px;
    color: #007bff;
}

.resend_otp_button {
    border: none;
    background: none;
    color: #007bff;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s ease;
    text-decoration: underline;
}

.resend_otp_button:hover {
    color: #0056b3;
}
