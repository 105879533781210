.event_page_container4 {
    margin-top: 140px;
    padding-inline: 5px;
    max-width: 100%; /* Prevents horizontal overflow */
    overflow-x: hidden; /* Hides any unintended horizontal overflow */
    box-sizing: border-box;
}

.event_details_description {
    all: unset; /* Reset all inherited styles */
    display: block; /* Ensure block-level behavior */
    white-space: pre-wrap; /* Preserve spaces and line breaks from the original content */
    max-width: 100%; /* Prevent content from exceeding container width */
    box-sizing: border-box; /* Include padding and borders in width calculation */
    padding: 0px 5px;
    text-align: justify !important;
}

.event_details_description img {
    max-width: 100%;
    width: 100%;
}

/* .ql-editor li {
    list-style-type: disc !important;
} */

/* .ql-editor {
    word-break: break-word;
} */

.event_details_container_inner {
    overflow-x: hidden; /* Prevent horizontal scrolling */
    width: 100%; /* Ensure it fits the container */
    box-sizing: border-box;
}
