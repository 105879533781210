.booking_details_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-top: 130px;
}

.booking_details_heading {
    width: 100%;
    display: flex;
    text-align: start;
    align-items: start;
    padding: 20px 15px 10px 15px;
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 5px;
    position: fixed;
    background-color: #fff;
    margin-top: -10px;
}

.booking_details_subheading {
    color: rgb(127, 184, 127);
    display: flex;
    text-align: start;
    align-items: start;
    padding: 0px 15px;
    font-size: 16px;
    font-weight: 400;
    margin-block: 10px;
}

.booking_details_full_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* overflow-y: scroll; */
    margin-top: 40px;
}

.booking_details_section_upper {
    padding: 2px 15px;
}

.booking_details_section1 {
    display: flex;
    padding: 13px 5px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f1f5f8;
}

.booking_details_section1_left {
    display: flex;
    align-items: center;
}

.booking_details_section1_right {
    display: flex;
    align-items: center;
    font-size: 15px;
}

.booking_details_section1_left1 {
    display: flex;
    align-items: center;
    font-size: 15px;
    padding-right: 8px;
}

.booking_details_section1_left2 {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-right: 10px;
}

.booking_details_section1_left3 {
    color:rgb(127, 184, 127);
    font-size: 10px;
    margin-left: -5px;
}

.booking_details_section_upper2 {
    background-color: white;
    padding: 0px 15px 20px 15px;
}

.booking_details_section2 {
    display: flex;
    flex-direction: column;
    background-color: #f1f5f8;
}

.booking_details_section1_heading {
    display: flex;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.booking_details_section1_heading_line {
    display: flex;
    height: 2px;
    width: 30px;
    background-color: black;
}

.booking_details_section1_subheading {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.booking_details_section3 {
    background-color: #f1f5f8;
    padding: 10px;
}

.booking_details_section3_inner {
    background-color: white;
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 2px 0px;
}

.block_page_container21 {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #f1f5f8;
    padding: 4px 0px;
    width: 24%;
}

.block_page_container21_active {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #36a334;
    color: white;
    padding: 4px 0px;
    width: 24%;
}

.booking_details_section12 {
    display: flex;
    flex-direction: column;
    padding: 5px 5px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #f1f5f8;
}

.booking_details_section1_left12 {
    display: flex;
    align-items: center;
    font-size: 10px;
    padding-right: 6px;
    color: #36a334;
}

.booking_details_section1_right2 {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.muna_outer{
    text-align: center;
}

.muna_inner{
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent; 
    border-right: 10px solid transparent; 
    border-top: 20px solid rgb(255, 255, 255); 
    margin: auto;
}

.book_tee_time_container2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.book_tee_time_heading2 {
    display: flex;
    text-align: start;
    align-items: start;
    padding: 0px 10px;
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 15px;
}

.book_tee_time_subheading2 {
    display: flex;
    text-align: start;
    align-items: start;
    padding: 0px 10px;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 28px;
}

.dates_container2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow-y: scroll; */
}

.date_button2 {
    width: 90%;
    font-size: 15px;
    padding: 12px 0px;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    background-color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
}

.date_button2:hover {
    background-color: #36a934;
    color: #fff;
}

.date_button3 {
    width: 90%;
    font-size: 16px;
    padding: 12px 0px;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    background-color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
    color: #36a934;
    font-weight: 600;
}

.date_button3:hover {
    background-color: #36a934;
    color: #fff;
}

.date_button4 {
    width: 90%;
    font-size: 16px;
    padding: 12px 0px;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    background-color: #fff;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
    color: #36a934;
    font-weight: 600;
}

.date_button4:hover {
    background-color: #36a934;
    color: #fff;
}

.more_dates_text2 {
    width: 90%;
    display: flex;
    justify-self: left;
    text-align: start;
    align-items: start;
    padding: 0px 10px;
    font-size: 13px;
    font-weight: 500;
    font-style: italic;
    color: #237522;
    margin-bottom: 20px;
}

.hole_buttons_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0px 0px 0px;
}

.hole_buttons_container2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    
}

.hole_button {
    width: 45%;
    font-size: 15px;
    padding: 12px 0px;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
}

.hole_button:hover {
    background-color: #36a934;
    color: #fff;
}

.hole_button_active {
    width: 45%;
    font-size: 15px;
    padding: 12px 0px;
    border-radius: 5px;
    background-color: #36a934;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
    color: #fff;
}

.hole_button_submit {
    width: 90%;
    font-size: 15px;
    padding: 12px 0px;
    border-radius: 5px;
    background-color: #36a934;
    margin-bottom: 10px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    color: #fff;
}

.hole_button_back {
    width: 90%;
    font-size: 15px;
    padding: 12px 0px;
    border-radius: 5px;
    background-color: #aaaaaa;
    margin-bottom: 10px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    color: #fff;
}

.hole_button_submit2 {
    width: 100%;
    font-size: 15px;
    padding: 12px 0px;
    border-radius: 5px;
    background-color: #36a934;
    margin-bottom: 10px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    color: #fff;
    margin-top: 10px;
}

.players_details_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    width: 100%;
    padding: 5px 2px;
    background-color: white;
}

.dropdown_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    padding-top: 10px;
    gap: 2px;
}

.dropdown_item {
    background-color: #f3f3f3;
    width: 96%;
    font-size: 16px;
    padding: 10px 10px;
}

.dropdown_item:hover {
    background-color: #36a934;
    color: #fff;
}

.joining_allow_div {
    display: flex;
    padding: 2px 0px;
    justify-content: flex-start;
}

.booking_time_section {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f1f5f8;
}

.booking_details_section1_heading {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booking_time_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.time_div_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 96%;
    padding-bottom: 3px;
}

.time_div_inner2 {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 96%;
    padding: 3px 0px;
}

.time_div_inner_left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96%;
    /* border: 1px solid #000000; */
    font-size: 16px;
    background-color: #ffffff;
    border-radius: 2px;
    padding: 12px 5px 12px 10px;
    font-weight: 600;
}

.time_div_inner_left_inner_right {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
}

.time_div_inner_right {
    width: 78%;
}

.slab_time{
color: #505050;
}

.time_div_inner_left2_inner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.time_div_inner_left2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 22%;
    font-size: 14px;
    font-weight: 600;
    color: #505050;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    /* padding: 12px 0px; */
    background-color: #ffffff;
    height: -webkit-fill-available;
}

.time_div_inner_left2_active {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22%;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    background-color: #36a934;
    border-radius: 3px;
    padding: 14px 0px;
}

.time_div_inner_right2 {
    width: 78%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c7c7c7;
    font-size: 15px;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 12px 0px;
    cursor: pointer;
}

.time_div_inner_right2_disabled {
    width: 78%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c7c7c7;
    font-size: 15px;
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 12px 0px;
}

.time_div_inner_right2_inner {
    display: flex;
    align-items: center;
    color: #757575;
    font-weight: 600;
}

.time_div_inner_right2_inner5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #757575;
    font-weight: 600;
}

.price_without_promotion {
    font-size: 11px;
    /* set strike throught */
    text-decoration: line-through;
    text-decoration-color: red;
}

.time_div_inner_right2_inner2 {
    display: flex;
    align-items: center;
    margin-right: 5px;
    padding-left: 10px;
    color: #757575;
    font-weight: 600;
}

.time_div_inner_right2_inner4 {
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 20px;
    color:rgb(59, 124, 59);
}

.time_div_inner_right2_inner3{
    padding-left: 10px;
    color: #3463a9;
    font-weight: 600;
}

.time_div_inner_right2_inner3_disabled {
    padding-left: 10px;
    color: #c7c7c7;
    font-weight: 600;
}

.time_div_inner_right2_inner3:hover{
    color:  #5d9eff
}

.user_details_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.user_details_div_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3px 0px;
}

.user_input {
    width: 100%;
    font-size: 15px;
    padding: 12px 10px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #c7c7c7;
}

.user_input_disabled {
    width: 100%;
    font-size: 15px;
    padding: 12px 10px;
    border-radius: 5px;
    background-color: #e6e6e6;
    border: 1px solid #dddddd;
}

.user_input_div {
    width: 90%;
    font-size: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.recaptcha_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.edit_booking_button {
    width: 60%;
    font-size: 15px;
    padding: 10px 0px;
    border-radius: 5px;
    border: none;
    background-color: #598a58;
    margin-bottom: 10px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    color: #fff;
}

.booking_extras_container {
    display: flex;
    flex-direction: column;
    padding: 0% 5%;
}

.extra_booking_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    background-color: #fff;
    margin-bottom: 5px;
}

.checkbox_group {
    display: flex;
    align-items: center;
}

.checkbox_label {
    padding-left: 10px;
}

.checkbox_input {
    width: 17px;
    height: 17px;
    cursor: pointer;
}

.booking_details_right {
    padding: 0% 4%;
    margin-top: 10px;
}

.booking_details_right_button {
    width: 100%;
    font-size: 16px;
    padding: 12px 0px;
    border-radius: 5px;
    border: none;
    background-color: #598a58;
    margin-bottom: 10px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    color: #fff;
}

.booking_details_right_button:disabled {
    background-color: #757575;
    display: none;
}

.close_button_container {
    display: flex;
    justify-content: end;
    padding-right: 10px;
    font-size: 20px;
    color: #646464;
    position: fixed;
    right: 10px;
}

.login_container_div_2 {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-top: 130px;
    box-shadow: 0px 0px 10px #b1b1b1;
    border-radius: 5px;
    padding-top: 10px;
    height: 70vh;
    overflow-y: scroll;
}

.login_link2 {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.promotion_name_timesheet {
    font-size: 10px;
    padding-left: 10px;
    color:rgb(59, 124, 59);
    font-weight: 600;
    text-align: center;
    margin-top: 5px;
}
