.terms_of_service_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 100px;
}

.terms_of_service_heading {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
    padding: 0px 20px;
    position: static;
}

.terms_of_service_content2 {
    margin: 10px 20px;
    padding: 10px;
    border: 2px solid #000000;
    overflow-y: scroll;
    height: calc(100vh - 210px);
}

.terms_of_service_heading_text {
    text-align: center;
}
