.event_booking_done_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 200px;
    padding: 0 20px;
    box-sizing: border-box;
}

.event_booking_done_heading {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 700;
}

.event_booking_done_heading p {
    margin: 5px 0;
}

.event_booking_done_heading p:first-child {
    font-size: 24px;
    font-weight: 700;
    color: #333;
}

.event_booking_done_content {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.players_details_button {
    width: 100%;
    padding: 10px;
    background-color: #e2e2e2;
    border: 1px solid #c0c0c0;
    border-radius: 8px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
}

.players_details_button:hover {
    background-color: #f5f5f5;
    border-color: #999;
}

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal_container {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal_header h2 {
    width: 90%;
    margin: 0;
    font-size: 20px;
    color: #333;
}

.modal_close {
    width: 5%;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
    text-align: right;
}

.modal_close:hover {
    color: #333;
}

.modal_content {
    padding: 10px 0;
}

.player_details_container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.player_detail {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.player_detail label {
    font-weight: 600;
    color: #666;
    font-size: 14px;
}

.player_detail span {
    color: #333;
    font-size: 16px;
    padding: 8px;
    background-color: #f5f5f5;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .event_booking_done_container {
        margin-top: 100px;
    }

    .event_booking_done_heading p:first-child {
        font-size: 20px;
    }

    .modal_container {
        width: 95%;
        margin: 10px;
    }
}
